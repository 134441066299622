<template>
  <div class="min-h-screen" v-if="languageLoaded">

  <!--Nav-->
  <nav id="header" class="fixed w-full z-30 top-0">
  <div v-bind:class="{'text-white': scrollAtTop, 'text-black': !scrollAtTop, 'bg-white': !scrollAtTop }"
        class="w-full flex flex-wrap items-center justify-between mt-0 pl-4 pr-2 py-2">
    <div class="flex items-center">
    <Link class="whitespace-no-wrap no-underline hover:no-underline font-bold text-2xl lg:text-4xl"  href="/">
      <picture v-if="scrollAtTop" id="navLogo" class="mainLogo">
      <source srcset="/images/logo_word_white.webp" type="image/webp">
      <source srcset="/images/logo_word_white.png" type="image/jpeg">
      <img style="height: 1em;" src="/images/logo_word_white.png" alt="Logo photasky">
      </picture>
      <picture v-else id="navLogo" class="mainLogo">
      <source srcset="/images/logo_word_black.webp" type="image/webp">
      <source srcset="/images/logo_word_black.png" type="image/jpeg">
      <img style="height: 1em;" src="/images/logo_word_black.png" alt="Logo photasky">
      </picture>
    </Link>
    </div>

    <div class="block lg:hidden pr-4">
    <button id="nav-toggle" class="bg-transparent hover:bg-transparent flex items-center p-1 text-white">
    <svg class="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>{{ $t('menu') }}</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
    </button>
    </div>

    <div class="w-full flex-grow lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-red-200 lg:bg-transparent text-black p-4 lg:p-0 z-20" id="nav-content">
    <ul v-bind:class="{'lg:text-white': scrollAtTop, 'lg:text-black': !scrollAtTop}" class="list-reset text-black lg:flex justify-end flex-1 items-center">
      <nav-item                  href="/"             icon="home"                 inertia="true">{{ $t('home') }}</nav-item>
      <nav-item                  href="/pricing"      icon="file-invoice-dollar"  inertia="true">{{ $t('pricing') }}</nav-item>
      <nav-item v-if="loggedIn"  href="/cardsets"     icon="paper-plane"          inertia="true">{{ $t('myCards') }}</nav-item>
      <nav-item v-if="loggedIn"  href="/user/profile" icon="user"                 inertia="true">{{ $t('userData') }}</nav-item>
      <nav-item v-if="loggedIn"  href="/logout"       icon="sign-out-alt"         inertia="true">{{ $t('logout') }}</nav-item>
      <nav-item v-if="!loggedIn" href="/register"     icon="user-plus"                          >{{ $t('register') }}</nav-item>
      <nav-item v-if="!loggedIn" href="/login"        icon="sign-in-alt"                        >{{ $t('login') }}</nav-item>
    </ul>
    </div>
  </div>
  <hr v-if="scrollAtTop" class="border-b border-gray-100 opacity-25 my-0 py-0" />
  </nav>

  <!--Hero-->
  <div class="pt-20 pb-16 px-8">
  <div class="text-right" :class="{'invisible': languageSupport != 'javascript'}">
    <a href="javascript:void(0)" @click="changeLocale('de');">DE</a> | <a href="javascript:void(0)" @click="changeLocale('en');">EN</a> | <a href="javascript:void(0)" @click="changeLocale('fr');">FR</a> | <a href="javascript:void(0)" @click="changeLocale('es');">ES</a>
  </div>
  <div class="text-right" :class="{'invisible': languageSupport != 'laravel'}">
    <a :href="'/de/' + ownRoute">DE</a> | <a :href="'/en/' + ownRoute">EN</a> | <a :href="'/fr/' + ownRoute">FR</a> | <a :href="'/es/' + ownRoute">ES</a>
  </div>
  <slot name="header"></slot>
  </div>

  <div class="relative -mt-12 lg:-mt-24">
  <svg viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fill-rule="nonzero">
  <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
  <path d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z" opacity="0.100000001"></path>
  <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" id="Path-4" opacity="0.200000003"></path>
  </g>
  <g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fill-rule="nonzero">
  <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
  </g>
  </g>
  </svg>
  </div>

  <section class="bg-white py-1 negMargin">
  <div class="lg:flex lg:flex-nowrap">
    <div class="container max-w-5xl mx-auto m-8">
    <div class="mt-2 px-2 lg:px-8 lg:text-xl text-gray-500">
      <flash-messages />
      <slot></slot>
    </div>
    </div>
  </div>
  </section>

  <!-- Change the colour #f8fafc to match the previous section colour -->
  <svg class="wave-top" viewBox="0 0 1439 147" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g transform="translate(-1.000000, -14.000000)" fill-rule="nonzero">
    <g class="wave" fill="#FFFFFF">
    <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"></path>
    </g>
    <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
    <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
    <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
    <path d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z" opacity="0.100000001"></path>
    <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" opacity="0.200000003"></path>
    </g>
    </g>
  </g>
  </g>
  </svg>

  <section class="container px-8 text-xl py-2 mb-2 leading-normal">
  <slot name="footer"></slot>
  </section>

  <!--Footer-->
  <footer class="bg-white">
  <div class="container mx-auto  px-8">
  <div class="w-full flex flex-col md:flex-row py-6">
    <div class="flex-1 mb-6">
    <Link class="whitespace-no-wrap text-black no-underline hover:no-underline font-bold text-xl lg:text-2xl"  href="/">
      <picture id="footerLogo">
        <source srcset="/images/logo_word_black.webp" type="image/webp" class="footerLogo">
        <source srcset="/images/logo_word_black.png" type="image/jpeg" class="footerLogo">
        <img src="/images/logo_word_black.png" alt="Logo photasky" class="footerLogo">
      </picture>
    </Link>
    </div>

    <div class="flex-1">
    <p class="uppercase text-gray-500 my-0 md:mb-6">{{ $t("links") }}</p>
    <ul class="list-reset mb-6">
    <bottom-link href="/help"           inertia="true">{{ $t("help") }}</bottom-link>
    <bottom-link href="/contact"        inertia="true">{{ $t("contactTitle") }}</bottom-link>
    <bottom-link href="/hochzeitstipps" inertia="true" v-if="languageGerman">{{ $t("weddingTips") }}</bottom-link>
    </ul>
    </div>
    <div class="flex-1">
    <p class="uppercase text-gray-500 my-0 md:mb-6">{{ $t("legal") }}</p>
    <ul class="list-reset mb-6">
      <bottom-link href="/terms"    inertia="true">{{ $t("Terms") }}</bottom-link>
      <bottom-link href="/gdpr"     inertia="true">{{ $t("PrivacyPolicy") }}</bottom-link>
      <bottom-link href="/imprint"  inertia="true">{{ $t("imprintTitle") }}</bottom-link>
    </ul>
    </div>
    <div class="flex-1">
    <p class="uppercase text-gray-500 my-0 md:mb-6">Social</p>
    <ul class="list-reset mb-6">
      <bottom-link href="https://www.facebook.com/photasky"        extern="true">Facebook</bottom-link>
      <bottom-link href="https://www.instagram.com/photasky.event" extern="true">Instagram</bottom-link>
      <bottom-link href="https://www.youtube.com/@photasky"        extern="true">YouTube</bottom-link>
      <bottom-link href="https://www.twitter.com/photasky"         extern="true">X</bottom-link>
      <bottom-link href="https://www.pinterest.com/photasky"       extern="true">Pinterest</bottom-link>
    </ul>
    </div>
  </div>
  </div>
  </footer>
  </div>
</template>

<script>
  import FlashMessages from '@/Shared/FlashMessages.vue'
  import AdWrapper from '@/Shared/AdWrapper.vue'
  import { setI18nLanguage, loadLocaleMessages } from '@/i18n';
  import NavItem from '@/Shared/NavItem.vue'
  import BottomLink from '@/Shared/BottomLink.vue'
  import { usePage } from '@inertiajs/vue3'
  import { Link } from '@inertiajs/vue3';

  export default {
  components: {
    AdWrapper,
    FlashMessages,
    NavItem,
    BottomLink,
    Link
  },

  props: {
    title: String,
    ownRoute: {
      default: ''
    },
    languageSupport: {
      default: 'none'
    },
    languageOvr: {
      default: null
    },
    displayAdditionalLanguages: {
      default: false
    },
    custom_gradient: {
      type: Object,
      default: null
    }
  },

  watch: {
    title: {
    immediate: true,
    handler(title) {
    document.title = title
    },
    },
  },

  data() {
    return {
    showingNavigationDropdown: false,
    scrollAtTop: true,
    languageLoaded: false
    }
  },

  mounted()
  {
    var language;
    if (this.languageOvr)
    {
      language = this.languageOvr;
    }
    else if (!(language=this.$storage.getStorageSync('language')))
    {
      switch (navigator.language.substring(0, 2))
      {
        case 'de': language = 'de'; break;
        case 'fr': language = 'fr'; break;
        case 'es': language = 'es'; break;
        default:   language = 'en';
      }
    }
    this.changeLocale(language);
  },

  methods: {
    changeLocale(locale) {
      this.$storage.setStorageSync('language', locale);
      var that = this;

      if (!that.$i18n.availableLocales.includes(locale)) {
        loadLocaleMessages(that.$i18n,locale).then(() => {
          that.languageLoaded = true;
          setI18nLanguage(that.$i18n, locale)
          that.finalizeLayout();
          that.$emit('langLoaded');
        })
      } else {
        that.languageLoaded = true;
        setI18nLanguage(that.$i18n, locale)
        that.finalizeLayout();
        that.$emit('langLoaded');
      }
    },
    setCustomGradient(grad) {
    var style = document.createElement('style');
    style.type = 'text/css';
    // override gradient CSS class
    style.innerHTML = '.gradient { background: linear-gradient(90deg, #' + grad.start + ' 0%, #' + grad.middle + ' 50%, #' + grad.end + ' 100%); }';
    document.getElementsByTagName('head')[0].appendChild(style);
    },
    finalizeLayout() {
    var self = this;

    if (this.custom_gradient)
    {
      this.setCustomGradient(this.custom_gradient);
    }

    document.addEventListener('scroll', function() {
      /*Apply classes for slide in bar*/
      var header     = document.getElementById("header");
      var navcontent = document.getElementById("nav-content");
      var navaction  = document.getElementById("navAction");
      var navbutton  = document.getElementById("nav-toggle");
      var toToggle   = document.querySelectorAll(".toggleColour");
      var scrollpos  = window.scrollY;

      if(scrollpos > 10)
      {
        self.scrollAtTop = false;
        if (navaction)
        {
          navaction.classList.replace("bg-white", "gradient");
          navaction.classList.replace("text-gray-800", "text-white");
        }
        //Use to switch toggleColour colours
        for (var i = 0; i < toToggle.length; i++)
        {
          toToggle[i].classList.replace("text-white", "text-gray-800");
        }
        header.classList.add("shadow");
        navcontent.classList.replace("bg-gray-100", "bg-white");
        navbutton.classList.replace("text-white", "text-black");
      }
      else
      {
        self.scrollAtTop = true;
        if (navaction)
        {
          navaction.classList.replace("gradient", "bg-white");
          navaction.classList.replace("text-white", "text-gray-800");
        }
        //Use to switch toggleColour colours
        for (i = 0; i < toToggle.length; i++)
        {
          toToggle[i].classList.replace("text-gray-800", "text-white");
        }
        header.classList.remove("shadow");
        navcontent.classList.replace("bg-white", "bg-gray-100");
        navbutton.classList.replace("text-black", "text-white");
      }
    });

    /*Toggle dropdown list*/
    /*https://gist.github.com/slavapas/593e8e50cf4cc16ac972afcbad4f70c8*/
    document.onclick = check;
    function check(e)
    {
      var navMenuDiv = document.getElementById("nav-content");
      var navMenu = document.getElementById("nav-toggle");
      var target = (e && e.target) || (event && event.srcElement);

      //Nav Menu
      if (!checkParent(target, navMenuDiv)) {
      // click NOT on the menu
      if (checkParent(target, navMenu)) {
        // click on the link
        if (navMenuDiv.classList.contains("hidden")) {
        navMenuDiv.classList.remove("hidden");
        } else {navMenuDiv.classList.add("hidden");}
      } else {
        // click both outside link and outside menu, hide menu
        navMenuDiv.classList.add("hidden");
      }
      }
    }
    function checkParent(t, elm)
    {
      while(t.parentNode) {
      if( t == elm ) {return true;}
      t = t.parentNode;
      }
      return false;
    }
    }
  },

  computed: {
    path() {
      return window.location.pathname
    },
    languageGerman() {
      return this.$i18n && this.$i18n.locale=='de';
    },
    loggedIn() {
      return usePage().props.auth.user
    }
  }
  }
</script>
